import { Injectable } from '@angular/core';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import { User } from '../models/user.model';


const USER_DATA = 'user-data';
const USER_TOKEN = 'user-token';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  setToken(token: string): void {
    window.sessionStorage.setItem(USER_TOKEN, token);
  }

  getToken(): string | null {
    return window.sessionStorage.getItem(USER_TOKEN);
  }

  setUserData(user: User): void {
    window.sessionStorage.setItem(USER_DATA, JSON.stringify(user));
    this.setToken(user.token);
  }

  getUserData(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    return user ? JSON.parse(user) : {};
  }

  clear(): void {
    window.sessionStorage.removeItem(USER_DATA);
    window.sessionStorage.removeItem(USER_TOKEN);
  }

  isTokenExpired(): boolean {
    const token = this.getToken()

    if (!token) return true

    try {

      const decodedToken = jwtDecode<JwtPayload>(token)

      if (!decodedToken.exp) return true

      return decodedToken.exp < (Date.now() / 1000)

    } catch(error) {
      console.error("Failed to decode jwtToken")
      return true
    }
  }

}
