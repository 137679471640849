import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
import { TokenService } from "../services/token.service";

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {

  const tokenService = inject(TokenService)
  const authenticationService = inject(AuthenticationService)
  const router = inject(Router);

  // Skip when user signins.
  if (req.url.includes("/userservice/authenticate")) return next(req)

  if (tokenService.isTokenExpired()) {
    authenticationService.deAuthenticate()
    router.navigate(['/signin'])

    return next(req)
  }

  const token = tokenService.getToken()

  const request = req.clone({
    setHeaders: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })

  return next(request)
}
