import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { provideToastr } from 'ngx-toastr';
import { routes } from './app.routes';
import { AppErrorHandler } from './core/services/app-error-handler.service';
import { errorToastrInterceptor } from './core/interceptor/error-toastr.interceptor';
import { authenticationInterceptor } from './core/interceptor/authentication.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideToastr(),
    CookieService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    provideHttpClient(withInterceptors([errorToastrInterceptor, authenticationInterceptor]))
  ]
};
