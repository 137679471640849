import { Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { AirbrakeNotifierService} from "./airbrake-notifier.service";

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  airbrakeNotifierService: AirbrakeNotifierService;

  constructor() {
    this.airbrakeNotifierService = new AirbrakeNotifierService();
  }

  handleError(error: any): void {
    this.airbrakeNotifierService.notifier.notify(error);
  }
}
